<template>
  <div v-if="page.length">
    <div class="relative">
      <img
        :src="isMobile ? page[0].img_m : page[0].img"
        alt=""
        class="width-100"
      >
      <div
        class="absolute-center "
        :class="isMobile ? 'f28' : 'f32'"
        :style="isMobile ? 'margin-left:-50px' : 'text-align:center; letter-spacing: 4px'"
      >
        <div
          class="b mb20"
          :class="isMobile ? 'f28' : 'f40 c-333'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
        >
          {{ page[0].t1 }}
        </div>
        <div
          class="c-333 f24"
          style="letter-spacing: 4px;"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          {{ page[0].t2 }}，{{ page[0].t3 }}
        </div>
      </div>
    </div>

    <div
      class="ptb50"
      :class="isMobile ? 'f16 tl plr20' : 'f20 tc'"
      :style="isMobile ? 'background:#F1F9FE;font-weight: lighter' : 'background:#FAFCFF;font-weight: lighter'"
    >
      <div
        v-for="item in page[0].t4"
        :key="item"
        :class="isMobile ? 'mb10' : ''"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="flip-right"
        data-aos-delay="200"
        data-aos-duration="1000"
      >
        {{ item }}
      </div>
    </div>

    <div class="ptb50 bg-fff">
      <div
        :class="isMobile ? '' : 'm-auto flex justify-between'"
        style=""
        :style="isMobile ? '' : 'width:1160px'"
      >
        <div
          :style="isMobile ? '' : 'width:320px;'"
          :class="isMobile ? 'plr20 mb40' : ''"
        >
          <div
            class="bd bb bd-ccc"
            :class="isMobile ? 'f28 mb10 pb10' : 'f40 mb20 pb20'"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
            data-aos="flip-right"
            data-aos-delay="200"
            data-aos-duration="1000"
          >
            {{ page[1].t1 }}
          </div>
          <div
            :class="isMobile ? 'f18' : 'f24'"
            style="font-weight:lighter"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
            data-aos="flip-right"
            data-aos-delay="200"
            data-aos-duration="1000"
          >
            {{ page[1].t2 }}
          </div>
        </div>
        <div
          class="tl"
          :class="isMobile ? 'f14 plr20' : 'f20'"
          style="font-weight:lighter;"
        >
          <div
            v-for="(item,i) in page[1].t3"
            :key="i"
            class="mb10"
            :class="{ mb40: i===6 }"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
            data-aos="flip-left"
            data-aos-delay="200"
            data-aos-duration="1000"
          >
            {{ item }}
          </div>

          <div style="margin-top: 45px; margin-bottom: 10px; font-size: 14px;">参考文献：</div>
          <div
            v-for="(item,i) in page[1].t4"
            :key="i"
            style="margin-bottom: 8px;"
            >
            <div style="font-size: 12px;">{{ item }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="relative">
      <img
        :src="isMobile ? page[2].img_m : page[2].img"
        alt=""
        class="width-100"
      >
      <div
        :class="isMobile ? 'p30' : 'absolute'"
        :style="isMobile ? {} : {
          top: `${30/3.75}vw`,
          left: `${40/2.5}vw`,
          width: '400px'
        }"
      >
        <div
          class="bd bb bd-ccc"
          :class="isMobile ? 'f28 mb10 pb10' : 'f40 mb15 pb15'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="flip-right"
          data-aos-delay="200"
          data-aos-duration="1000"
        >
          {{ page[2].t1 }}
        </div>
        <div
          style="font-weight:lighter"
          class="mb40"
          :class="isMobile ? 'f18' : 'f24'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="flip-right"
          data-aos-delay="200"
          data-aos-duration="1000"
        >
          {{ page[2].t2 }}
        </div>
        <div
          v-for="(item,i) in page[2].t3"
          :key="i"
          class="mb10"
          :class="isMobile ? 'f14' : 'f20'"
          style="font-weight:lighter"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="flip-right"
          data-aos-delay="200"
          data-aos-duration="1000"
        >
          {{ item }}
        </div>
      </div>
    </div>

    <div
      v-for="(item,i) in page[3].d"
      :key="i"
    >
      <div
        :class="isMobile ? '' : 'flex'"
      >
        <div
          v-if="isMobile || (!isMobile && i===1)"
          :class="isMobile ? '' : 'width-50'"
        >
          <img
            :src="item.img"
            alt=""
            class="width-100"
          >
        </div>
        <div
          class="flex justify-center flex-column"
          :class="isMobile ? 'p30' : 'vw-pl30 width-50'"
        >
          <div
            class="bd bb bd-ccc"
            :class="isMobile ? 'f28 mb20 pb10' : 'f40 pb20 mb50'"
            :style="isMobile ? '' : 'width:290px'"
            v-vue-aos.once="{animationClass:'fadeInUp animated'}"
            data-aos="flip-right"
            data-aos-delay="200"
            data-aos-duration="1000"
          >
            {{ item.t1 }}
          </div>
          <div style="font-weight:lighter">
            <div
              v-for="(item,i) in item.t2"
              :key="i"
              class="mb10"
              :class="isMobile ? 'f14' : 'f20'"
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="flip-right"
              data-aos-delay="200"
              data-aos-duration="1000"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div
          v-if="!isMobile && i!==1"
          class="width-50"
        >
          <img
            :src="item.img"
            alt=""
            class="width-100"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: []
    }
  },

  async created () {
    const { data } = await this.fetch('/pinpai.json')
    this.page = data
  },

  methods: {
  }
}
</script>
